export const HelpLinks = {
  login:
    'https://faq.bell-face.com/%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84-5e65b2684a7a7c00170ad024',
  systemRequirements:
    'https://faq.bell-face.com/%E3%83%9B%E3%82%B9%E3%83%88%E5%81%B4_%E5%8B%95%E4%BD%9C%E7%92%B0%E5%A2%83-5e65b2664a7a7c00170acfb0',
  chatSupport:
    'https://faq.bell-face.com/%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88-5e65b2674a7a7c00170acfb8',
  passwordReset:
    'https://faq.bell-face.com/%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%81%AE%E5%86%8D%E7%99%BA%E8%A1%8C-5e65b2684a7a7c00170ad023',
  register: 'https://faq.bell-face.com/--613710774adb14001d5bb696',
} as const
