import { VFC } from 'react'
import styled from 'styled-components'
import { Colors } from '@/styles'

export type Props = {
  /**
   * for Attribute
   */
  readonly htmlFor?: string
  readonly children: string
}

export const FormLabel: VFC<Props> = ({ htmlFor, children }) => (
  <StyledFormLabel htmlFor={htmlFor} className="form-label">
    {children}
  </StyledFormLabel>
)

const StyledFormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1;
  color: ${Colors.text};
`
