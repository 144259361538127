import { VFC, useMemo } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { Colors, ColorTypeValues, IconName } from '@/styles'

export type Props = {
  readonly style?: 'default' | 'outlined' | 'round' | 'sharp' | 'two-tone'
  readonly color?: ColorTypeValues
  /**
   * pass for styled method
   */
  readonly className?: string
  readonly children: IconName
}

export const Icon: VFC<Props> = ({ style = 'default', color = Colors.gray, className, children }) => {
  const defaultClass = 'material-icons'
  const iconClass = useMemo(() => (style === 'default' ? defaultClass : `${defaultClass}-${style}`), [style])
  return (
    <MaterialIcon className={classnames(className, iconClass, 'icon')} color={color}>
      {children}
    </MaterialIcon>
  )
}

const MaterialIcon = styled.span<Pick<Props, 'color'>>`
  color: ${({ color }) => color};
  background: transparent;
`
