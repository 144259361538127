import { APIErrorUnknown } from './APIErrors'

export * from './APIErrors'
export * from './messages'
export * from './types'

export function normalizeAPIError(type: string | undefined) {
  const m = type?.match('https://problems.accounts.bellfaceapis.com/([0-9a-z_]*)')
  return m ? m[1] : APIErrorUnknown
}
