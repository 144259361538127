import {
  forwardRef,
  ComponentProps,
  ComponentPropsWithoutRef,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { v4 as uuid } from 'uuid'
import { Colors } from '@/styles'
import { Notes } from '@/constants'

import { FormFieldError } from '@atoms/FormFieldError'
import { FormLabel } from '@atoms/FormLabel'
import { IconButton } from '@atoms/IconButton'
import { Tooltip } from '@atoms/Tooltip'

export type Props = Partial<{
  readonly className: string
  readonly label: string
  readonly type: 'text' | 'email' | 'password' | 'number'
  readonly defaultValue: string
  readonly disabledFieldError: boolean
}> &
  Pick<ComponentProps<typeof FormFieldError>, 'error'> &
  Omit<ComponentPropsWithoutRef<'input'>, 'type'>

export const TextField = forwardRef(
  (
    { id, className, label, type = 'text', autoFocus, error, disabledFieldError = false, ...rest }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    const inputId = useRef(id ? id : `text-field-${uuid()}`)

    const inputError = useMemo(() => error && 'invalid', [error])
    const inputType = useMemo(() => {
      if (type === 'password') {
        return passwordVisible ? 'text' : 'password'
      }
      return type
    }, [type, passwordVisible])

    const togglePasswordVisibility = useCallback(() => setPasswordVisible(passwordVisible => !passwordVisible), [])

    useEffect(() => {
      if (autoFocus) {
        const inputElement = document.getElementById(inputId.current)
        inputElement && inputElement.focus()
      }
    }, [autoFocus])

    return (
      <StyledTextField className={className}>
        {label && <FormLabel htmlFor={inputId.current}>{label}</FormLabel>}
        <StyledInput className={classnames(inputError)}>
          <TextInput id={inputId.current} type={inputType} ref={ref} {...rest} />
          {type === 'password' && (
            <ButtonArea>
              <IconButton
                style="outlined"
                name={passwordVisible ? 'visibility' : 'visibility_off'}
                onClick={togglePasswordVisibility}
              />
            </ButtonArea>
          )}
        </StyledInput>
        {!disabledFieldError && (
          <>
            <FormFieldError error={error} />
            <Tooltip targetSelector="#specialCharHelpContent">{Notes.specialChar}</Tooltip>
          </>
        )}
      </StyledTextField>
    )
  },
)
TextField.displayName = 'TextField'

const StyledTextField = styled.div``

const StyledInput = styled.div`
  display: flex;
  width: 100%;
  background: ${Colors.backgroundWhite};
  border: 1px solid ${Colors.inputBorder};
  border-radius: 4px;

  &:focus-within {
    border-color: ${Colors.inputFocus};
  }

  &.invalid {
    border-color: ${Colors.inputError};
  }
`

const TextInput = styled.input`
  flex: 1 0 auto;
  width: 0;
  padding: 12px 16px;
  line-height: 1.5;
  color: ${Colors.text};
  border: none;
  border-radius: 4px;
  outline: none;
  appearance: none;

  &::-ms-reveal {
    display: none;
  }

  &::placeholder {
    color: ${Colors.placeholder};
  }

  &:disabled {
    background: ${Colors.backgroundGray};
  }
`

const ButtonArea = styled.div`
  padding: 8px 12px 8px 0;
`
