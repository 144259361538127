import { VFC, ReactNode } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { Colors } from '@/styles'

export type Props = Partial<{
  readonly theme: 'default' | 'brand'
  readonly title: string
  /**
   * pass for styled method
   */
  readonly className: string
  readonly children: ReactNode
}>

export const Panel: VFC<Props> = ({ theme = 'default', title, className, children }) => (
  <StyledPanel className={classnames(className, theme, 'panel')}>
    {title && <Title className={classnames(theme, 'title')}>{title}</Title>}
    {children}
  </StyledPanel>
)

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: ${Colors.text};
  background: ${Colors.backgroundWhite};
  border-radius: 16px;

  &.brand {
    color: ${Colors.textWhite};
    background: ${Colors.backgroundBrand};
  }
`

const Title = styled.h2`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  text-align: center;
`
