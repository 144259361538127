import { VFC, ReactNode, MouseEvent } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { Colors } from '@/styles'

export type Props = Partial<{
  readonly theme: 'primary' | 'cancel'
  /**
   * type Attribute
   */
  readonly type: 'button' | 'submit' | 'reset'
  /**
   * pass for styled method
   */
  readonly className: string
  readonly disabled: boolean
  readonly onClick: (e: MouseEvent<HTMLButtonElement>) => void
  readonly children: ReactNode
}>

export const Button: VFC<Props> = ({ theme = 'primary', type = 'button', className, children, ...rest }) => (
  <StyledButton className={classnames(theme, className)} type={type} {...rest}>
    {children}
  </StyledButton>
)

const StyledButton = styled.button`
  min-width: 140px;
  height: 48px;
  padding: 16px 32px;
  font-weight: bold;
  line-height: 1;
  color: ${Colors.buttonText};
  background: ${Colors.button};
  border: none;
  border-radius: 100px;

  &:hover {
    background: ${Colors.buttonHover};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    background: ${Colors.buttonFocus};
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.white}, 0 0 0 3px ${Colors.buttonFocusOutline};
  }

  &:active {
    color: ${Colors.buttonActiveText};
    background: ${Colors.buttonActive};
  }

  &:disabled {
    background: ${Colors.buttonDisabled};
  }

  &.cancel {
    color: ${Colors.buttonCancelText};
    background: ${Colors.buttonCancel};
    border: 1px solid ${Colors.buttonCancelBorder};

    &:hover {
      background: ${Colors.buttonCancelHover};
    }

    &:focus-visible {
      background: ${Colors.buttonCancelFocus};
    }

    &:active {
      color: ${Colors.buttonCancelText};
      background: ${Colors.buttonCancelActive};
    }

    &:disabled {
      background: ${Colors.buttonCancelDisabled};
      opacity: 0.4;
    }
  }
`
