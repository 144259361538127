import { VFC, ReactNode } from 'react'
import styled from 'styled-components'

import { Colors } from '@/styles'
import { Typography } from '@atoms/Typography'

export type Props = {
  /**
   * pass for styled method
   */
  readonly className?: string
  readonly children: ReactNode
}

export const FormError: VFC<Props> = ({ className, children }) => (
  <StyledFormError className={className}>{children}</StyledFormError>
)

const StyledFormError = styled(Typography)`
  color: ${Colors.textError};
`
