import { VFC, ComponentProps, MouseEvent } from 'react'
import styled from 'styled-components'
import { Colors, IconName } from '@/styles'

import { Icon } from '@atoms/Icon'

export type Props = {
  readonly name: IconName
  readonly disabled?: boolean
  /**
   * pass for styled method
   */
  readonly className?: string
  readonly onClick?: (e: MouseEvent<HTMLButtonElement>) => void
} & Pick<ComponentProps<typeof Icon>, 'style' | 'color'>

export const IconButton: VFC<Props> = ({ name, style, color, ...rest }) => (
  <StyledIconButton type="button" {...rest}>
    <Icon style={style} color={color}>
      {name}
    </Icon>
  </StyledIconButton>
)

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: ${Colors.backgroundWhite};
  border: none;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.9);
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    filter: brightness(0.9);
    outline: none;
    box-shadow: 0 0 0 1px ${Colors.buttonFocusOutline};
  }

  &:active {
    filter: brightness(0.8);
  }

  &:disabled {
    opacity: 0.4;
  }
`
