import { VFC, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import classnames from 'classnames'
import { useWindowClick } from '@/hooks/useWindowClick'
import { Colors } from '@/styles'

type Position = {
  offsetX: number
  offsetY: number
}

export type Props = {
  readonly theme?: 'error'
  readonly targetSelector: string
  readonly children: string
}

const ARROW_SIZE = 8

export const Tooltip: VFC<Props> = ({ theme = 'error', targetSelector, children }) => {
  const [mounted, setMounted] = useState(false)
  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState<Position>({
    offsetX: 0,
    offsetY: 0,
  })
  const ref = useRef<HTMLDivElement>(null)

  const getPosition = useCallback(() => {
    const targetElement: HTMLElement | null = document.querySelector(targetSelector)
    if (!targetElement || !ref.current) return

    const offsetX =
      targetElement.getBoundingClientRect().left +
      (targetElement.offsetWidth - ref.current.getBoundingClientRect().width) / 2
    const offsetY = targetElement.getBoundingClientRect().top + targetElement.offsetHeight + ARROW_SIZE + 4

    return { offsetX, offsetY }
  }, [targetSelector])

  useWindowClick(e => {
    const targetElement = document.querySelector(targetSelector)
    if (targetElement?.contains(e.target as HTMLElement)) return
    !ref.current?.contains(e.target as HTMLElement) && setVisible(false)
  }, visible)

  useEffect(() => setMounted(true), [])
  useEffect(() => {
    if (!mounted) return
    const targetElement = document.querySelector(targetSelector)
    const setTooltipPosition = () => {
      const elementPosition = getPosition()
      if (!elementPosition) return
      setPosition(elementPosition)
    }
    const show = () => {
      setTooltipPosition()
      setVisible(!visible)
    }
    const move = () => {
      if (visible) {
        setTooltipPosition()
      }
    }

    targetElement?.addEventListener('click', show)
    window.addEventListener('resize', move)
    window.addEventListener('scroll', () => setVisible(false))

    return () => {
      targetElement?.removeEventListener('click', show)
      window.removeEventListener('resize', move)
      window.removeEventListener('scroll', () => setVisible(false))
    }
  })

  return mounted
    ? createPortal(
        <StyledTooltip
          className={classnames(theme, visible && 'visible')}
          offsetX={position.offsetX}
          offsetY={position.offsetY}
          ref={ref}
        >
          {children}
        </StyledTooltip>,
        document.body,
      )
    : null
}

const StyledTooltip = styled.div<Position>`
  position: fixed;
  top: ${({ offsetY }) => `${offsetY}px`};
  left: ${({ offsetX }) => `${offsetX}px`};
  width: 205px;
  padding: 8px 16px;
  font-size: 14px;
  color: ${Colors.textWhite};
  border-radius: 4px;
  opacity: 0;

  &.error {
    background: ${Colors.error};
  }

  &.visible {
    opacity: 1;
  }

  &::before {
    position: absolute;
    top: -8px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-right: ${ARROW_SIZE}px solid transparent;
    border-bottom: ${ARROW_SIZE}px solid ${Colors.error};
    border-left: ${ARROW_SIZE}px solid transparent;
    transform: translateX(-50%);
  }
`
