export const LoginId = {
  required: 'メールアドレスを入力してください',
  pattern: {
    value: /^\S+@\S+$/i,
    message: 'メールアドレスの形式で入力してください',
  },
} as const

export const Email = {
  required: 'メールアドレスを入力してください',
  maxLength: { value: 200, message: '200文字以下で入力してください' },
  pattern: {
    value: /^\S+@\S+$/i,
    message: 'メールアドレスの形式で入力してください',
  },
} as const

export const LoginPassword = {
  required: 'パスワードを入力してください',
} as const

export const Password = {
  required: 'パスワードを入力してください',
  minLength: { value: 9, message: 'パスワードは9文字以上で入力してください' },
  maxLength: { value: 72, message: 'パスワードは72文字以下で入力してください' },
  validate: {
    requiredChar: (value: string) => /^(?=.*[a-z])(?=.*[\d])/i.test(value) || '半角英数を組み合わせて入力してください',
    availableChar: (value: string) =>
      /^[a-z0-9@#$%^&*_!+=[\]{}()|/\\:',.?`~";-]+$/i.test(value) ||
      '<span id="specialCharHelpContent" class="text-underline">パスワードに使用できる記号</span>を入力してください',
  },
} as const
