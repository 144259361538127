import { ErrorMessage, ErrorTypeNotificateMessage } from '@/error'
import { useState, useCallback } from 'react'

import { useCsrfToken } from '../contexts/csrfToken'

export type Options = {
  readonly uri: string
  readonly method: 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  readonly headers?: Record<string, string>
  readonly beforeChangeSucceededDelay?: number
}

export type MutationStatus = 'initialized' | 'sending' | 'failed' | 'succeeded'

type ErrorObj = {
  readonly type: string
  readonly message: string
}

type InitailizedStats = {
  readonly status: 'initialized'
  readonly data: null
  readonly error: null
}

type SendingStats<T> = {
  readonly status: 'sending'
  readonly data: T | null
  readonly error: ErrorObj | null
}

type SucceededStats<T> = {
  readonly status: 'succeeded'
  readonly data: T
  readonly error: null
}

type FailedStats = {
  readonly status: 'failed'
  readonly data: null
  readonly error: ErrorObj
}

export type Stats<T> = InitailizedStats | SendingStats<T> | SucceededStats<T> | FailedStats

function isJsonResponse(res: Response) {
  const contentType = res.headers.get('content-type')
  if (!contentType) return false
  return contentType.toLowerCase().indexOf('application/json') !== -1
}

export function useMutation<T = unknown, S = unknown>(options: Options) {
  const [stats, setStats] = useState<Stats<S>>({
    status: 'initialized',
    data: null,
    error: null,
  })
  const csrfToken = useCsrfToken()

  const mutate = useCallback(
    async (body?: T) => {
      const fetchOptions = body
        ? {
            body: JSON.stringify(body),
            method: options.method,
            headers: {
              'Content-type': 'application/json',
              ...options.headers,
            },
          }
        : {
            method: options.method,
            headers: {
              ...options.headers,
            },
          }
      if (csrfToken) {
        ;(fetchOptions.headers as Record<string, string>)['X-Csrf-Token'] = csrfToken
      }
      try {
        setStats({
          ...stats,
          status: 'sending',
        })
        const res = await fetch(options.uri, fetchOptions)
        if (!res.ok) {
          if (!isJsonResponse(res)) {
            setStats({
              status: 'failed',
              data: null,
              error: {
                type: ErrorTypeNotificateMessage,
                message: ErrorMessage.Retrinable,
              },
            })
            return
          }
          const errorObj = await res.json()
          setStats({
            status: 'failed',
            data: null,
            error: {
              type: errorObj?.type || ErrorTypeNotificateMessage,
              message: errorObj?.message || ErrorMessage.Retrinable,
            },
          })
          return
        }
        if (!isJsonResponse(res)) {
          // WAF エラーとかを個別にハンドリングする場合はここで細分化する
          setStats({
            status: 'succeeded',
            data: {} as S,
            error: null,
          })
          return
        }
        const data = (await res.json()) as S

        setTimeout(() => {
          setStats({
            status: 'succeeded',
            data,
            error: null,
          })
        }, options.beforeChangeSucceededDelay || 0)
      } catch (e: any) {
        setStats({
          status: 'failed',
          data: null,
          error: {
            type: ErrorTypeNotificateMessage,
            message: e?.message || ErrorMessage.Retrinable,
          },
        })
      }
    },
    [stats, csrfToken, options],
  )

  return {
    ...stats,
    mutate,
  }
}
