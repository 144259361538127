import { VFC, ReactNode } from 'react'
import styled from 'styled-components'

export type Props = {
  /**
   * pass for styled method
   */
  readonly className?: string
  readonly children: ReactNode
}

export const Typography: VFC<Props> = ({ className, children }) => (
  <StyledTypography className={className}>{children}</StyledTypography>
)

const StyledTypography = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-feature-settings: normal;
  line-height: 1.5;
  letter-spacing: normal;

  & > * {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .large {
    font-size: 16px;
  }

  .bold {
    font-weight: bold;
  }

  .center {
    text-align: center;
  }

  .spacing {
    &__compressed {
      margin-bottom: 0;
    }

    &__small {
      margin-bottom: 8px;
    }

    &__large {
      margin-bottom: 32px;
    }
  }
`
