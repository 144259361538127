export const ErrorMessage = {
  Retrinable: '問題が発生しました。しばらく経ってからやり直してください。',
  CSRFTokenInvalid: 'ページの有効期限が切れました。ページ再読込してやり直してください。',
  InvalidMailFormat: 'メールアドレスの形式で入力してください。',
  InvalidRecentlyUsePassword:
    'このパスワードは利用できません。現在または、その一つ前に設定したパスワード以外を入力してください。',
  InvalidCredentialsLogin: 'メールアドレスかパスワードが一致しません。3回失敗すると、アカウントがロックされます。',
  InvalidCredentialsReAuth: 'パスワードが一致しません。正しいパスワードを入力してください。',
  ResetTokenExpired: 'URLの有効期限が切れました。再度、リセットの手順をやり直してください。',
  ResetTokenDisabled: 'URLの有効期限が切れた、または不正です。再度、リセットの手順をやり直してください。',
  InvalidRegisterEmail: 'メールアドレスが一致しません。招待されたメールアドレスを入力してください。',
  IDTokenHintVerificationFailure: 'Tokenの有効期限が切れました。元ページからやり直してください。',
}

export default ErrorMessage
