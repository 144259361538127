import { useEffect } from 'react'

export const useWindowClick = (callback: (e: MouseEvent) => void, enabled = true) => {
  useEffect(() => {
    if (enabled) {
      window.removeEventListener('click', callback, true)
      window.addEventListener('click', callback, true)

      return () => {
        window.removeEventListener('click', callback, true)
      }
    }
    return undefined
  }, [enabled, callback])
}
