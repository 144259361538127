import { VFC, ComponentProps, useMemo } from 'react'
import styled from 'styled-components'
import { Colors, IconName } from '@/styles'

import { Icon } from '@atoms/Icon'
import { IconButton } from '@atoms/IconButton'

export type Props = Partial<{
  readonly theme: 'success' | 'info' | 'error'
  readonly children: string
}> &
  Pick<ComponentProps<typeof IconButton>, 'onClick'>

export const Snackbar: VFC<Props> = ({ theme = 'success', children, onClick }) => {
  const themeIcon: IconName = useMemo(() => {
    if (theme === 'info') return 'info'
    if (theme === 'error') return 'error'
    return 'check_circle'
  }, [theme])

  return (
    <StyledSnackbar className={theme}>
      <StyledIcon color={Colors.textWhite}>{themeIcon}</StyledIcon>
      <StyledText>{children}</StyledText>
      <StyledIconButton name="close" color={Colors.textWhite} onClick={onClick} />
    </StyledSnackbar>
  )
}

const StyledSnackbar = styled.div`
  display: inline-flex;
  align-items: center;
  min-width: 240px;
  max-width: 340px;
  height: 50px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.textWhite};
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);

  &.success {
    background: ${Colors.success};
  }

  &.info {
    background: ${Colors.info};
  }

  &.error {
    background: ${Colors.error};
  }
`

const StyledIcon = styled(Icon)`
  width: 18px;
  margin-right: 8px;
  font-size: 18px;
`

const StyledText = styled.span`
  max-height: 36px;
  margin-right: auto;
  overflow: hidden;
`

const StyledIconButton = styled(IconButton)`
  margin-left: 16px;
  background: transparent;

  & > .icon {
    font-size: 16px;
  }
`
