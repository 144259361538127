import { VFC, useMemo } from 'react'
import Image from 'next/image'
import LogoDefault from '@/assets/images/logo.svg'
import LogoWhite from '@/assets/images/logo-white.svg'

export type Props = {
  readonly theme?: 'default' | 'white'
}

export const Logo: VFC<Props> = ({ theme = 'default' }) => {
  const logoUrl = useMemo(() => (theme === 'default' ? LogoDefault : LogoWhite), [theme])
  return <Image src={logoUrl} alt="Brand Logo" width={200} height={82} />
}
