import styled from 'styled-components'
import { FieldError } from 'react-hook-form'
import { Colors } from '@/styles'

export type Props = {
  /**
   * react-hook-form FieldError type
   */
  readonly error?: FieldError
}

export const FormFieldError = ({ error }: Props) => (
  <StyledFormFieldError dangerouslySetInnerHTML={{ __html: error?.message || '' }} />
)

const StyledFormFieldError = styled.span`
  display: block;
  height: 21px;
  margin-top: 4px;
  font-size: 14px;
  color: ${Colors.textError};

  & > .text-underline {
    text-decoration: underline;
    cursor: pointer;
  }
`
