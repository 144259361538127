import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import classnames from 'classnames'

import { Snackbar } from '@molecules/Snackbar'

export type Props = Partial<{
  readonly visibility: boolean
  readonly autoHide: boolean
  readonly onHide: () => void
}> &
  Pick<ComponentProps<typeof Snackbar>, 'theme' | 'children'>

const AUTO_HIDE_DURATION = 5000

export const Notification = ({ visibility = false, autoHide = false, onHide, theme, children }: Props) => {
  const [mounted, setMounted] = useState(false)
  const handleHide = useCallback(() => onHide && onHide(), [onHide])

  useEffect(() => setMounted(true), [])
  useEffect(() => {
    if (autoHide) {
      const timeoutId = setTimeout(() => handleHide(), AUTO_HIDE_DURATION)
      return () => clearTimeout(timeoutId)
    }
  }, [autoHide, handleHide])

  return mounted
    ? createPortal(
        <StyledNotification className={classnames(visibility && 'visible')}>
          <Snackbar theme={theme} onClick={handleHide}>
            {children}
          </Snackbar>
        </StyledNotification>,
        document.body,
      )
    : null
}

const StyledNotification = styled.div`
  position: fixed;
  top: -62px;
  right: 48px;
  transition: top 0.3s ease-in-out;

  &.visible {
    top: 48px;
  }
`
