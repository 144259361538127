// transition を伴うエラー
export const ErrorTypeLoginIdLock = 'login_id_locked'
export const ErrorTypeLoginMissMatch = 'login_id_missmatch'
export const ErrorTypeNotJoinTenant = 'join_tenant_not_exist'
export const ErrorTypeAlreadyJoined = 'already_joined'
export const ErrorTypeMultiTenantsJoined = 'multi_tenants_joined'
export const ErrorTypeAuthnRequired = 'authn_required'
export const ErrorTypeReAuthnRequired = 'reauthn_required'
export const ErrorTypeLoginRequired = 'login_required'
export const ErrorTypePasswordResetTokenExpired = 'reset_token_expired'
export const ErrorTypePasswordResetTokenDisabled = 'reset_token_disabled'
export const ErrorTypeForcePasswordChange = 'force_password_change'
export const ErrorTypeDisabledPasswordLogin = 'disabled_password_login'
export const ErrorTypeInvalidInvitationCode = 'invalid_invitation_code'
export const ErrorTypeAccountLimitExceeded = 'account_limit_exceeded'

export const ErrorTypeAlreadyExist = 'already_exist'

// ページ内に表示するエラー
export const ErrorTypePageMessage = 'page_message'
// snack bar で表示するエラー
export const ErrorTypeNotificateMessage = 'noficate_message'

// query string経由でくるエラータイプ
export const MessageTypePasswordResetTokenExpired = 'reset_token_expired'
export const MessageTypePasswordResetTokenDisabled = 'reset_token_disabled'
